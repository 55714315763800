import styled from "styled-components";

export const ComingSoonMain = styled.div`
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 70vh; /* Makes the container fill the full viewport height */
  width: 100%;
  @media (min-width: 1440px) {
    height: 70vh;
  }
  @media (max-width: 1024px) {
    height: 60vh;
  }
`;
export const ComingSoonMainRow = styled.div`
  margin: auto;
`;
export const ShopifyGenerateMain = styled.div`
  /* padding-top: 10rem; */
  margin-top: 2rem;

  /* @media (max-width: 1024px) {
    margin-top: 0rem;
  }
  @media (max-width: 820px) {
    padding-top: 5rem;
  }
  @media (max-width: 600px) {
    padding-top: 0;
  } */
  &.backendslider {
    padding-bottom: 15rem;
    @media (max-width: 600px) {
      padding-bottom: 9rem;
    }
  }
`;

export const ShopifyGenerateHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ShopifyGenerateH5 = styled.div`
  font-size: 58px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: -0.2px;

  svg.GenerateSvg {
    width: 100%;
  }
  @media (max-width: 1180px) {
    font-size: 50px;
    .fullsizesvg {
      bottom: -23px;
      right: -30px;
    }
  }
  @media (max-width: 820px) {
    font-size: 35px;
    .Generate {
      top: -13px;
      right: -6px;
    }
    .fullsizesvg {
      width: 50%;
    }
  }
  @media (max-width: 571px) {
    font-size: 20px;
    line-height: 35px;
  }

  @media (max-width: 430px) {
    .Generate {
      top: -8px;
      right: -4px;
    }
    .fullsizesvg svg {
      width: 50%;
    }
    .fullsizesvg {
      bottom: -10px;
    }
  }
`;

export const ShopifyGenerateP = styled.div`
  font-size: 26px;
  line-height: 42px;
  letter-spacing: -0.2px;
  width: 59%;
  padding-top: 1.1rem;

  @media (max-width: 1180px) {
    width: 80%;
  }
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 26px;
    padding-top: 1.1rem;
  }
  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 32px;
  }
  @media (max-width: 778px) {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.8px;
    width: 100%;
  }
  @media (max-width: 479px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const ShopifyGenerateBody = styled.div`
  display: flex;
  padding-bottom: 1rem;
  justify-content: center;
  flex-wrap: wrap;

  .Grey {
    background: #e4e4e4;
    margin-top: 8rem;

    @media (max-width: 1022px) {
      padding-bottom: 30px;
    }
    @media (max-width: 820px) {
      margin-top: 3rem;
    }
    @media (max-width: 768px) {
      padding-bottom: 0px;
    }
  }
  .Pink {
    background: #ccf095;

    @media (max-width: 768px) {
      padding-bottom: 0px;
    }

    @media (max-width: 1200px) {
      width: 90%;
      margin-top: 10rem;
    }
    @media (max-width: 430px) {
      width: 100%;
    }
  }

  @media (max-width: 1200px) {
    .GenerateImg {
      right: 7rem;
    }
  }
  @media (max-width: 820px) {
    .Gray {
      height: 0;
      padding-bottom: 30px;
    }
  }

  @media (max-width: 600px) {
    .Gray {
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
`;

export const ShopifyCardButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .WooCommerceArrow {
    svg {
      height: 150px;
    }
  }
  @media (max-width: 1022px) {
    margin-top: 20px;
  }
  @media (max-width: 778px) {
    margin-top: 2rem;
  }
  @media (max-width: 430px) {
    display: Block;
  }
  button.btn.btn-secondary {
    background: none;
    border: none;
    text-transform: uppercase;
    font-size: 11.63px;
    line-height: 12px;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    padding-left: 0;
    height: fit-content;
    @media (max-width: 1022px) {
      font-size: 7px;
    }
    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
  svg.iconify.iconify--formkit {
    margin-left: 5px;

    font-size: 9.63px;
  }
  .GenerateArrow {
    @media (max-width: 1180px) {
      height: 13vh;
    }
    @media (max-width: 1024px) {
      height: 13vh;
    }

    @media (max-width: 820px) {
      &.GenerateArrow {
        height: 8vh;
      }
    }
    @media (max-width: 430px) {
      display: none;
    }
  }
`;

export const ShopifyCardDiv = styled.div`
  width: 90%;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  padding: 2rem;
  margin-top: 12rem;

  @media (max-width: 778px) {
    width: 97%;
    padding: 1rem;
  }

  @media (max-width: 430px) {
    width: 100%;
    display: block;
    margin-top: 3rem;
    &.Grey {
      min-height: 97.5vh;
    }
    &.Pink {
      min-height: 103.5vh;
      margin-top: 3rem;
    }
    &.cream {
      min-height: 103vh;
    }
    &.greyTwo {
      min-height: 103.5vh;
    }
    &.blue {
      min-height: 110.5vh;
    }
  }
  @media (max-width: 414px) {
    min-height: 105vh;
    &.Grey {
      min-height: 102vh;
    }
    &.Pink {
      min-height: 109.5vh;
    }
    &.cream {
      min-height: 106vh;
    }
    &.blue {
      min-height: 119.5vh;
    }
    &.greyTwo {
      min-height: 105vh;
    }
  }
  @media (max-width: 400px) {
    min-height: 145vh;
    &.Grey {
      min-height: 130vh;
    }
    &.Pink {
      min-height: 137vh;
    }
  }
  @media (max-width: 390px) {
    min-height: 115vh;
    &.Grey {
      min-height: 105vh;
    }
    &.Pink {
      min-height: 116vh;
    }
    &.cream {
      min-height: 113vh;
    }
    &.blue {
      min-height: 125vh;
    }
    &.greyTwo {
      min-height: 109vh;
    }
  }
  @media (max-width: 375px) {
    min-height: 145vh;
    &.Grey {
      min-height: 135vh;
    }
    &.Pink {
      min-height: 147vh;
    }
    &.cream {
      min-height: 141vh;
    }
    &.blue {
      min-height: 156vh;
    }
    &.greyTwo {
      min-height: 145vh;
    }
  }
`;
export const ShopifyCardLeft = styled.div`
  padding-left: 20px;
  width: 50%;
  @media (max-width: 768px) {
    padding-left: 0px;
    width: 52%;
  }
  @media (max-width: 430px) {
    width: 100%;
    padding-left: 0px;
  }
`;

export const ShopifyCardLeftImg = styled.div`
  width: 85%;
  box-shadow: 0px 4px 14px 0px #00000017;
  position: relative;
  right: 13rem;
  top: 2rem;
  height: auto;
  border-radius: 18px;

  @media (min-width: 1440px) {
    &.GenerateImg {
      right: 6rem;
      top: 1rem;
      width: 100%;
    }
    &.WordpressGenerateImg {
      right: 6rem;
      top: 1rem;
      width: 100%;
    }
  }
  @media (max-width: 1366px) {
    &.GenerateImg {
      right: 7rem;
    }
    &.WordpressGenerateImg {
      right: 7rem;
    }
  }
  @media (max-width: 1024px) {
    &.GenerateImg {
      right: 5.5rem;
    }
    &.WordpressGenerateImg {
      right: 5rem;
    }
  }
  @media (max-width: 820px) {
    &.GenerateImg {
      right: 6rem;
    }
    &.WordpressGenerateImg {
      right: 6rem;
    }
  }
  @media (max-width: 768px) {
    &.GenerateImg {
      right: 0rem;
    }
    &.WordpressGenerateImg {
      right: 0rem;
    }
  }
  @media (max-width: 430px) {
    width: 100%;
  }
  // @media (max-width: 1025px) {
  //   right: 6rem;
  //   left: 0;
  // }
  // @media (min-width: 1180px) {
  //   right: 0rem;
  // }
  // @media (min-width: 1566px) {
  //   right: 7rem;
  // }
  // @media (max-width: 600px) {
  //   left: 0;
  //   right: 0;
  //   margin: 0 auto;
  // }
`;

export const ShopifyCardRight = styled.div`
  width: 50%;
  position: relative;
  @media (max-width: 430px) {
    width: 100%;
    top: 44px;
  }
`;

export const ShopifyCardRightImg = styled.div`
  margin-top: 5rem;
  border-radius: 14px;

  &.WooCommerceGenerate {
    margin-top: 2rem;
  }
  @media (max-width: 778px) {
    top: 6rem;
    position: static;
    margin-top: 2rem;
  }
  @media (max-width: 1022px) {
    top: 1rem;
  }
  @media (max-width: 820px) {
    &.MySquardCardBottom {
      margin-top: 2rem;
    }
  }
`;

export const TitleH5 = styled.h5`
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
  width: 90%;

  @media (max-width: 1022px) {
    font-size: 15px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 30px;
  }
  @media (max-width: 639px) {
    font-size: 18px;
    line-height: 26px;
    width: 100%;
    letter-spacing: 0.5px;
    br {
      display: none;
    }
  }
`;

export const TextP = styled.div`
  font-size: 22.19px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: -0.3px;
  width: 100%;
  padding-top: 14px;

  @media (max-width: 1279px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (max-width: 1024px) {
    &.GenerateText {
      font-size: 16px;
      line-height: 30px;
    }
  }
  @media (max-width: 820px) {
    &.GenerateText {
      font-size: 12px;
      line-height: 20px;
    }
  }
  @media (max-width: 768px) {
    &.GenerateText {
      font-size: 16px;
      line-height: 30px;
    }
  }
`;

export const ShopifyImageMain = styled.div`
  .button-main {
    display: flex;
    justify-content: center;
  }
  button.btn.btn-secondary {
    background: #d25494;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
  }
  @media (max-width: 820px) {
    padding-bottom: 3rem;
  }
`;
export const HomeTwoMySquardImgMain = styled.div`
  background-image: url(https://www.dilate.com.au/wp-content/uploads/2023/12/background-case-studies-kustom-timber.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .button-main {
    display: flex;
    justify-content: center;
  }
  button.btn.btn-secondary {
    background: #d25494;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
  }
  @media (max-width: 820px) {
    padding-bottom: 3rem;
  }
`;

export const ShopifyHowWeAddMain = styled.div`
  margin-top: 9rem;
  @media (max-width: 430px) {
    margin-top: 1rem;
  }
`;

export const ShopifyHowWeAddHeader = styled.div`
  padding-bottom: 3rem;
  @media (max-width: 779px) {
    padding-top: 5rem;
    padding-bottom: 0rem;
  }
`;

export const ShopifyHowWeAddH3 = styled.h3`
  text-align: center;
  font-size: 58px;
  font-weight: 600;
  line-height: 71px;
  letter-spacing: -0.2px;

  margin: 0 auto;
  @media (max-width: 1025px) {
    font-size: 50px;
  }
  @media (max-width: 779px) {
    font-size: 25px;
    line-height: 37px;
    width: 100%;
  }
`;

export const ShopifyHowWeAddBody = styled.div`
  margin-top: 6rem;
  @media (max-width: 778px) {
    margin-top: 5rem;
  }
  @media (max-width: 430px) {
    margin-top: 1rem;
  }
  .card {
    border: none;
    border-radius: 14px;
    background: transparent;

    .card-body {
      padding: 2rem;
      border: 1px solid #fff;
      border-radius: 15px;
      background: #fff;

      @media (max-width: 778px) {
        padding: 5px;
        margin-top: 1rem;
      }
    }
  }
`;

export const ShopifyHowWeAddBThead = styled.thead``;

export const ShopifyHowWeAddBTr = styled.tr``;

export const ShopifyHowWeAddTh = styled.th`
  font-size: 16px;
  text-transform: uppercase;
  // color: #1d1e22;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: 2px;

  @media (max-width: 778px) {
    font-size: 14px;

    line-height: 25px;
  }
`;

export const ShopifyHowWeAddTd = styled.td`
  padding-top: 30px !important;
  width: 50%;
  padding-left: 0px;
  &.border-start.border-end.ps-3 {
    width: 20%;
  }
`;

export const ShopifyHowWeAddBTbody = styled.tbody`
  tr:last-child {
    border-bottom: #ffffff00;
  }
`;

export const ShopifyHowWeAddTdh3 = styled.h3`
  font-size: 24.68px;
  font-weight: 700;
  line-height: 40px;
  @media (max-width: 778px) {
    font-size: 13px;
    line-height: 20px;
  }
`;

export const ShopifyHowWeAddTdP = styled.div`
  line-height: 24px;
  width: 84%;
  padding-top: 20px;
  padding-bottom: 30px;
  @media (max-width: 820px) {
    font-size: 10px;
    line-height: 17px;
    width: 100%;
  }
`;

export const ShopifyHowWeAddTdLi = styled.li`
  padding-bottom: 10px;
  @media (max-width: 820px) {
    font-size: 10px;
    line-height: 10px;
    width: 100%;
  }
`;

export const HowWeWorkAsResultMain = styled.div`
  padding-top: 10rem;
  @media (max-width: 430px) {
    padding-top: 5rem;
  }
`;

export const HowWeWorkAsResultHeader = styled.div`
  display: flex;
  justify-content: center;
  width: 88%;
  @media (max-width: 1024px) {
    width: 77%;
  }
  @media (max-width: 430px) {
    width: 100%;
  }

  .AsResultSvg {
    margin-top: 10px;
    margin-right: 70px;
  }

  @media (max-width: 430px) {
    .AsResultSvg {
      display: none;
    }
  }
`;

export const HowWeWorkAsResultH3 = styled.h3`
  font-size: 30px;
  font-weight: 600;
  line-height: 47px;
  text-align: center;
  letter-spacing: -0.2px;

  @media (max-width: 430px) {
    &.AsResultTitle {
      font-size: 20px;
      line-height: 35px;
      width: 100%;
    }
  }
  @media (max-width: 778px) {
    font-size: 20px;
    line-height: 35px;
  }

  @media (max-width: 820px) {
    &.AsResultTitle {
      font-size: 22px;
      line-height: 35px;
      width: 100%;
    }
  }
  @media (max-width: 1023px) {
    font-size: 40px;
    line-height: 56px;
    width: 100%;
  }
`;
export const SoftwareTodayMain = styled.div``;
export const SoftwareVideoMain = styled.div`
  margin: 2rem 0;

  img {
    width: 100%;
    height: auto;
  }
`;
export const SoftwareTodayTitle = styled.div`
  font-size: 58px;
  font-weight: 600;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 46px;
  }
  @media (max-width: 991px) {
    font-size: 34px;
  }
  @media (max-width: 479px) {
    font-size: 28px;
  }
`;
export const SoftwareCardsMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  &.counter-row {
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }
  @media screen and (min-width: 1440px) {
    &.counter-row {
      grid-column-gap: 24px;
      grid-row-gap: 0px;
    }
  }
  @media (max-width: 991px) {
    display: flex;
    flex-wrap: wrap;
    &.counter-row {
      grid-column-gap: 24px;
      grid-row-gap: 0px;
    }
  }
  @media screen and (max-width: 767px) {
    &.counter-row {
      grid-template-columns: 1fr;
      grid-column-gap: 16px;
      grid-row-gap: 16px;
    }
  }
`;
export const SoftwareCardItems = styled.div`
  width: 25%;
  height: 296px;
  border: 0.5px solid var(--border-color);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  &.case-count-col {
    width: 100%;
    max-height: 282px;
    padding: 40px;
  }
  &.counter-col {
    width: 100%;
    max-height: 422px;
    border: 0px solid var(--border-color);
  }
  &.active {
    background: #ff76bb;
    border: none;
  }
  &:hover {
    border-color: #000;
  }
  @media screen and (min-width: 1440px) {
    &.counter-col {
      padding: 55px;
      min-height: 422px;
    }
    &.case-count-col {
      padding: 40px;
      max-height: 282px;
    }
  }
  @media (max-width: 991px) {
    width: 45%;
    .counter-col {
      min-height: 290px;
      padding: 24px;
    }
    &.case-count-col {
      padding: 24px;
      max-height: 282px;
    }
  }
  @media screen and (max-width: 767px) {
    .counter-col {
      min-height: 246px;
      margin-bottom: 16px;
    }
    &.case-count-col {
      margin-bottom: 16px;
      max-height: 282px;
    }
  }
  @media (max-width: 479px) {
    width: 100%;
    height: 215px;
    .counter-col {
      min-height: 214px;
    }
    &.case-count-col {
      max-height: 282px;
    }
  }
`;
export const SoftwareTopHeading = styled.div`
  font-size: 58px;
  font-weight: 500;
  &.case-heading {
    font-size: 42px;
    line-height: 42px;
    font-weight: 500;
    color: #000000;
  }

  &.active {
    font-size: 34px;
    font-weight: 500;
    line-height: 44px;
    color: #000000;
  }
  @media (max-width: 1024px) {
    font-size: 46px;
  }
  @media (max-width: 991px) {
    font-size: 34px;
    &.active {
      font-size: 34px;
      line-height: 35px;
    }
  }
  @media (max-width: 479px) {
    font-size: 28px;
    &.active {
      font-size: 28px;
      line-height: 35px;
    }
  }
`;
export const ApplicationServicesHeading = styled.div`
  font-size: 20px;
  font-weight: 500;

  @media (max-width: 1366px) {
    font-size: 18px;
  }
`;
export const SoftwareBottomText = styled.div`
  font-size: 26px;
  font-weight: 400;
  color: #000000;
  &.case-subtxt {
    font-size: 19px;
    line-height: 26px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 52px;
  }
  svg {
    width: 30px;
    height: 30px;
  }
`;
export const ApplicationServicesText = styled.div`
  font-size: 18px;
  font-weight: 400;
  @media (max-width: 1366px) {
    font-size: 18px;
  }
`;

export const ApplicationServicesCardItems = styled.div`
  width: 25%;
  height: 296px;
  border: 0.5px solid #d5d4d4;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  background: ${(props) => (props.bg ? props.bg : `#1D1E22`)};
  color: ${(props) => (props.color ? props.color : ``)};
  &.active {
    background: #ff76bb;
    border: none;
  }
  &:hover {
    border-color: #000;
  }
  @media (max-width: 991px) {
    width: 45%;
  }
  @media (max-width: 479px) {
    width: 100%;
    height: 270px;
  }
`;
